
import React from 'react';
import VideoLink from './../../components/VideoLink';

const Seven = () => {
    return (
        <>
            <h1>Kapitel 7 Montessoripedagogik i praktik och teori</h1>
            <VideoLink embedId={'UoH0UhKw96s'} />
        </>
    );
};
export default Seven;