import React from 'react'
import * as GiIcons from 'react-icons/gi';
import * as AiIcons from 'react-icons/ai';
import * as GrIcons from 'react-icons/gr';
import * as MDIcons from 'react-icons/md';


export const SidebarContent = [
    {
        title: 'home',
        path: '/hem',
        icon: <AiIcons.AiFillHome />,
    },
    {
        title: 'Boken',
        path: '/bok',
        icon: <GiIcons.GiBookCover />,
        iconClosed: <MDIcons.MdArrowUpward />,
        iconOpen: <MDIcons.MdClose />,
        subNav: [
            { kapitel: 'Första', path: '/kapitel1', icon: <GrIcons.GrInfo /> },
            { kapitel: 'Andra', path: '/kapitel2', icon: <GrIcons.GrInfo /> },
            { kapitel: 'Tredje', path: '/kapitel3', icon: <GrIcons.GrInfo /> },
            { kapitel: 'Fjärde', path: '/kapitel4', icon: <GrIcons.GrInfo /> },
            { kapitel: 'Femte', path: '/kapitel5', icon: <GrIcons.GrInfo /> },
            { kapitel: 'Sjätte', path: '/kapitel6', icon: <GrIcons.GrInfo /> },
            { kapitel: 'Sjunde', path: '/kapitel7', icon: <GrIcons.GrInfo /> },
            { kapitel: 'Åttånde', path: '/kapitel8', icon: <GrIcons.GrInfo /> },
            { kapitel: 'Nionde', path: '/kapitel9', icon: <GrIcons.GrInfo /> },
            { kapitel: 'Tionde', path: '/kapitel10', icon: <GrIcons.GrInfo /> },
            { kapitel: 'Elfte', path: '/kapitel11', icon: <GrIcons.GrInfo /> },
            { kapitel: 'Tolfte', path: '/kapitel12', icon: <GrIcons.GrInfo /> },
            { kapitel: 'Trettonde', path: '/kapitel13', icon: <GrIcons.GrInfo /> },
            { kapitel: 'Fjortonde', path: '/kapitel14', icon: <GrIcons.GrInfo /> },
        ]
    },
    {
        title: 'Beställning',
        path: '/bestall',
        icon: <AiIcons.AiOutlineShopping />
    },
    {
        title: 'Föreläsningar',
        path: '/forelasningar',
        icon: <GiIcons.GiGraduateCap />
    }
]
