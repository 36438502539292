
import React from 'react';
import VideoLink from './../../components/VideoLink';

const Six = () => {
    return (
        <>
            <h1>Kapitel 6 Montessoripedagogik i praktik och teori</h1>
            <VideoLink embedId={'pqPWTzP1L60'} />
        </>
    );
};

export default Six;
