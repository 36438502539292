import React from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';
import './../App.css';

const VideoResponsive = styled.div`
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    margin-right: 40px;
    margin-left: 40px;
`


const VideoLink = ({ embedId }) => (
    <VideoResponsive>
        <iframe
            className="responsive-iframe"
            max-width="853"
            max-height="480"
            src={`https://www.youtube.com/embed/${embedId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
        />
    </VideoResponsive>
);

VideoLink.propTypes = {
    embedId: PropTypes.string.isRequired
};

export default VideoLink;