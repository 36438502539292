import React from 'react'
import styled from 'styled-components';

const Wrapper = styled.div`
display: flex;
flex-direction: column;
margin-left: 400px;
margin-right: 400px;
@media (max-width: 998px) {
    margin-left: 60px;
    margin-right: 60px;
  }
@media (max-width: 768px) {
    margin-left: 40px;
    margin-right: 40px;
  }
`;

const Forelasningar = props => {
    return (
        <Wrapper>
            <h1>Föreläsningar</h1>
            <h3>Maila frågor: <a href="mailto:Info@utbildning2000.se">Info@utbildning2000.se</a></h3>
        </Wrapper >
    )
}


export default Forelasningar
