import React from 'react';
import VideoLink from './../../components/VideoLink';

const Thirteen = () => {
    return (
        <>
            <h1>Kapitel 13s Montessoripedagogik i praktik och teori</h1>
            <VideoLink embedId={'-rzDkZb6MT0'} />
        </>
    );
};

export default Thirteen