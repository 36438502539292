import React from 'react';
import VideoLink from './../../components/VideoLink';

const Eleven = () => {
    return (
        <>
            <h1>Kapitel 11 Montessoripedagogik i praktik och teori</h1>
            <VideoLink embedId={'RHpS0LR_6u0'} />
        </>
    );
};

export default Eleven