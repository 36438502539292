import React from 'react'
import styled from 'styled-components';
import Front from '../images/Front.jpg';

const Wrapper = styled.div`
display: flex;
margin-left: 400px;
margin-right: 400px;
@media (max-width: 998px) {
    margin-left: 60px;
    margin-right: 60px;
  }
@media (max-width: 768px) {
    margin-left: 40px;
    margin-right: 40px;
    flex-direction: column;
  }
`;

const ListItem = styled.li`
    font-weight: bold;
    margin-bottom: 8px;
    text-align: left;
`;

const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
      flex-direction: column;
      }
`;

const SellingBlock = styled.ul`
    background: #f7f7f7;
    padding: 40px;
    border-radius: 12px;
    @media (max-width: 768px) {
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
      }
`;

const KeySellingBlock = styled.ul`
    background: #f7f7f7;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 12px;
    border 1px solid black;
    @media (max-width: 768px) {
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 40px;
      }
`;

const StyledImage = styled.img`
    max-width: 430px;
    height: auto;
    padding: 10px;
`;

const OrderText = styled.div`
      font-size:20px;
      font-weight: bold;
      text-align: left;
`;

const OrderTextHeader = styled.div`
      font-size:20px;
      font-weight: bold;
      text-align: left;
      padding-bottom: 14px;
      padding-top: 14px;
`;

const Email = styled.a`
      color: blue;
      font-size:18px;
`;

const PriceWrapper = styled.div`
      display: flex;
      flex-direction: row;
      margin-top: 8px
`;

const PriceNumber = styled.div`
      color: red;
      margin-left: 4px;
`;

const Price = styled.div`
     color: black;
     display: flex;
     font-size: 20px;
     font-weight: bold;
     display: flex;
     align-items: baseline;
     @media (max-width: 768px) {
        font-size: 16px;
      }
`;

const Vat = styled.div`
      font-size: 12px;
      margin-left: 4px;
`;

const AdditionalInfo = styled.div`
    font-weight: bold;
    text-align: left;
    margin-top: 8px;

`;

const Bestall = props => {

    return (
        <div>
            <h1>Beställning, gör så här:</h1>
            <Wrapper>
                <ImageWrapper>
                    <StyledImage src={Front} alt={'book cover'} />
                </ImageWrapper>

                <SellingBlock>
                    <OrderText>Beställning sker via email: <Email href="mailto:Info@utbildning2000.se">Info@utbildning2000.se</Email></OrderText>
                    <OrderTextHeader>Ange:</OrderTextHeader>
                    <KeySellingBlock>
                        <ListItem>antal böcker</ListItem>
                        <ListItem>namn och address dit böckerna skall skickas</ListItem>
                        <ListItem>namn och address dit faktura skall skickas om annan än leveransadressen</ListItem>
                    </KeySellingBlock>
                    <PriceWrapper>
                        <Price>Pris: <PriceNumber>379 kr </PriceNumber> <Vat>ink. moms. Frakt tillkommer</Vat> </Price>
                    </PriceWrapper>
                    <AdditionalInfo>Vid beställning av 10 eller fler böcker erhålles 10% rabatt</AdditionalInfo>
                </SellingBlock>
            </Wrapper>
        </div >
    )
}
export default Bestall
