
import React from 'react';
import VideoLink from './../../components/VideoLink';

const Five = () => {
    return (
        <>
            <h1>Kapitel 5 Montessoripedagogik i praktik och teori</h1>
            <VideoLink embedId={'-m56-zTKiiE'} />
        </>
    );
};

export default Five;