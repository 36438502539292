import React from 'react'
import styled from 'styled-components';
import Front from '../images/Front.jpg';
import Back from '../images/Back.jpg';

const Wrapper = styled.div`
display: flex;
flex-direction: column;
margin-left: 400px;
margin-right: 400px;
@media (max-width: 998px) {
    margin-left: 60px;
    margin-right: 60px;
  }
@media (max-width: 768px) {
    margin-left: 40px;
    margin-right: 40px;
  }
`;

const TextWrapper = styled.div`
text-align:center;
margin-bottom: 40px;
`;

const ListItem = styled.li`
font-weight: bold;
margin-bottom: 8px;
text-align: left;
`;

const SellingBlock = styled.ul`
    background: #f7f7f7;
    padding: 40px;
    border-radius: 12px;
    @media (max-width: 768px) {
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 40px;
      }
`;

const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
      flex-direction: column;
      }
`;

const StyledImage = styled.img`
max-width: 500px;
height: auto;
padding: 10px;
`

const Bok = props => {
    return (
        <Wrapper>
            <h1>Montessoripedagogik i praktik och teori</h1>
            <h3> - En studiebok</h3>
            <TextWrapper>Det finns ett stort behov och efterfrågan av att få ny personal som börjar arbeta på montessoriförskolor och montessorigrundskolor insatt i pedagogiken på ett effektivt sätt. Därför har den här boken skrivits. Boken lyfter montessoripedagogiken en bit in på 2000-talet och vänder sig till många olika målgrupper;</TextWrapper>

            <SellingBlock>
                <ListItem>För nyanställd personal för att få förståelse för arbetssätt i montessoriförskolor och montessorigrundskolor</ListItem>
                <ListItem>Som kursbok för montessoriassistentutbildning</ListItem>
                <ListItem>Som grundbok och komplement till Montessoris litteratur i montessorilärarutbildningar</ListItem>
                <ListItem>Som fortbildning och repetition för redan utbildad personal i montessoriskolor</ListItem>
                <ListItem>Den kan också användas av vårdnadshavare eller andra intresserade som vill lära sig mer om montessoripedagogik</ListItem>
            </SellingBlock>
            <ImageWrapper>
                <StyledImage src={Front} alt={'book cover'} />
                <StyledImage src={Back} alt={'book cover'} />
            </ImageWrapper>
        </Wrapper >
    )
}


export default Bok
