import React from 'react';
import VideoLink from './../../components/VideoLink';

const Nine = () => {
    return (
        <>
            <h1>Kapitel 9 Montessoripedagogik i praktik och teori</h1>
            <VideoLink embedId={'ybWyyJOXf5M'} />
        </>
    );
};

export default Nine