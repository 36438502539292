
import React from 'react';
import VideoLink from './../../components/VideoLink';

const Two = () => {
    return (
        <>
            <h1>Kapitel 2 Montessoripedagogik i praktik och teori</h1>
            <VideoLink embedId={'yirXlN8R9ds'} />
        </>
    );
};

export default Two;