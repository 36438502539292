import React from 'react'
import styled from 'styled-components';
import ProduktBlad from '../images/produktblad.png';
import { Link } from 'react-router-dom';

const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
      flex-direction: column;
      }
`;

const StyledImage = styled.img`
    max-width: 430px;
    height: auto;
    padding: 10px;
`;

const OrderButton = styled(Link)`
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 40px;
      padding-right: 40px;
      background: #e3b0a3;
      opacity: 0.9;
      border-radius: 25px;
      text-decoration: none;
      color: black;
      max-width: 100px;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;


const Home = () => {
    return (
        <Wrapper>
            <h1>Montessoripedagogik i praktik och teori</h1>
            <OrderButton to={'/bestall'}>Beställ här</OrderButton>
            <ImageWrapper>
                <StyledImage src={ProduktBlad} alt={'book cover'} />
            </ImageWrapper>
        </Wrapper>
    )
}

export default Home
