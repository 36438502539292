import React from 'react';
import VideoLink from './../../components/VideoLink';

const Three = () => {
    return (
        <>
            <h1>Kapitel 3 Montessoripedagogik i praktik och teori</h1>
            <VideoLink embedId={'yuQ0k5UDMXY'} />
        </>
    );
};

export default Three;