import React from 'react';
import VideoLink from './../../components/VideoLink';

const Fourteen = () => {
    return (
        <>
            <h1>Kapitel 14 Montessoripedagogik i praktik och teori</h1>
            <VideoLink embedId={'hqLaWRLQ9_E'} />
        </>
    );
};

export default Fourteen