
import React from 'react';
import VideoLink from './../../components/VideoLink';

const Eight = () => {
    return (
        <>
            <h1>Kapitel 8 Montessoripedagogik i praktik och teori</h1>
            <VideoLink embedId={'wzv9Isbnp1o'} />
        </>
    );
};

export default Eight