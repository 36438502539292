
import './App.css';
import Sidebar from './components/Sidebar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import One from './pages/chapter/one';
import Two from './pages/chapter/two';
import Three from './pages/chapter/three';
import Four from './pages/chapter/four';
import Five from './pages/chapter/five';
import Six from './pages/chapter/six';
import Seven from './pages/chapter/seven';
import Eight from './pages/chapter/eight';
import Nine from './pages/chapter/nine';
import Ten from './pages/chapter/ten';
import Eleven from './pages/chapter/eleven';
import Twelve from './pages/chapter/twelve';
import Thirteen from './pages/chapter/thirteen';
import Fourteen from './pages/chapter/fourteen';
import Bok from './pages/bok';
import Home from './pages/home';
import Bestall from './pages/Bestall';
import Forelasningar from './pages/forelasningar';

const Wrapper = styled.div`
width: 100%;
text-align: center;
margin: auto;
`

function App() {
  return (
    <Wrapper>
      <Router>
        <Sidebar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/hem' element={<Home />} />
          <Route path='/bok' element={<Bok />} />
          <Route path='/bestall' element={<Bestall />} />
          <Route path='/kapitel1' element={<One />} />
          <Route path='/kapitel2' element={<Two />} />
          <Route path='/kapitel3' element={<Three />} />
          <Route path='/kapitel4' element={<Four />} />
          <Route path='/kapitel5' element={<Five />} />
          <Route path='/kapitel6' element={<Six />} />
          <Route path='/kapitel7' element={<Seven />} />
          <Route path='/kapitel8' element={<Eight />} />
          <Route path='/kapitel9' element={<Nine />} />
          <Route path='/kapitel10' element={<Ten />} />
          <Route path='/kapitel11' element={<Eleven />} />
          <Route path='/kapitel12' element={<Twelve />} />
          <Route path='/kapitel13' element={<Thirteen />} />
          <Route path='/kapitel14' element={<Fourteen />} />
          <Route path='/forelasningar' element={<Forelasningar />} />
        </Routes>
      </Router>
    </Wrapper>
  );
}

export default App;